// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCWlQn-okv4rqcr-aMCvIxM2JPQn0E2jGk",
  authDomain: "greposhare.firebaseapp.com",
  projectId: "greposhare",
  storageBucket: "greposhare.appspot.com",
  messagingSenderId: "905355093871",
  appId: "1:905355093871:web:dcaca7e4efe3d685f5aa9a",
  measurementId: "G-K1JRPMFBKH"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app)