import React, { useState } from 'react';
import { BrowserRouter as Router, NavLink } from 'react-router-dom';
import logoURL from '../../images/Eagle.png';
import { useUserContext } from "../../context/userContext";
import './sidebar.scss';
import { getAuth } from "firebase/auth";

const Sidebar = () => {
  const { logoutUser } = useUserContext();
  const [selectedItem, setSelectedItem] = useState("NONE");
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const fetchAccounts = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const idToken = await user.getIdToken();
      
      const response = await fetch('/api/accounts', {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });

      const data = await response.json();
      setOptions(data);
    } catch (error) {
      console.error('Error fetching accounts:', error);
    }
  };

  const toggleDropdown = async () => {
    if (!isDropdownOpen) {
      await fetchAccounts(); // Fetch accounts when opening the dropdown
    }
    setDropdownOpen(!isDropdownOpen);
  };

  const handleSelect = (item) => {
    setSelectedItem(item);
    setDropdownOpen(false); // Close dropdown when an item is selected
  };

  return (
    <Router>
      <div className="sidebar-holder">
        <div className="logo">
          <img src={logoURL} alt="React" />
        </div>

        <h3>Account:</h3>
        <div className="dropdown">
          <button className="dropdown-button" onClick={toggleDropdown}>
            {selectedItem}
          </button>
          {isDropdownOpen && (
            <div className="dropdown-content">
              {options.map((option, index) => (
                <div key={index} onClick={() => handleSelect(option)} className="button">{option}</div>
              ))}
            </div>
          )}
        </div>

        <h3>Menu</h3>
        <div className="menu">
          <NavLink to="/worldmap" exact activeClassName="active" className="button">
            <span className="text">World Map</span>
          </NavLink>
          <NavLink to="/cityoverview" activeClassName="active" className="button">
            <span className="text">City Overview</span>
          </NavLink>
          <NavLink to="/unitmovements" activeClassName="active" className="button">
            <span className="text">Unit Movements</span>
          </NavLink>
          <NavLink to="/trademovements" activeClassName="active" className="button">
            <span className="text">Trade Movements</span>
          </NavLink>
          <NavLink to="/remoteorders" activeClassName="active" className="button">
            <span className="text">Remote Orders</span>
          </NavLink>
        </div>

        <div className="flex"></div>

        <div className="menu">
          <NavLink to="/settings" activeClassName="active" className="button">
            <span className="text">Settings</span>
          </NavLink>
          <NavLink to="/" activeClassName="active" className="button" onClick={logoutUser}>
            <span className="text">Logout</span>
          </NavLink>
        </div>
      </div>
    </Router>
  );
};

export default Sidebar;
