import Dashboard from "./components/dashboard";
import { useUserContext } from "./context/userContext";
import backgroundImage from './images/greposhare_auth_background.png';
import Signin from "./components/signin";

function App() {
  const { user, loading, error } = useUserContext();

  const appStyle = !user ? {
    backgroundColor: "#92a8d1;",
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    minHeight: '100vh',
    flexDirection: 'column',
  } : {}

  return (
    <div className="App" style={appStyle} >
      {error && <p className="error">{error}</p>}
      {loading ? <h2>Loading...</h2> : <> {user ? <Dashboard /> : 
      <>
        <h1 className="title">GrepoShare</h1>
        <div className="container">
          <Signin />
        </div>
      </>
      } </>}
    </div>
  );
}

export default App;