import './OrderViewer.scss';
import React, { useState, useEffect, useMemo } from 'react';
import { format, isToday, isTomorrow, addDays } from 'date-fns';

const OrderViewer = ({ data, currentTime, isVisibleType, isVisiblePrediction, inputValueOrigin, inputValueDestination}) => {
    const [glowRed, setGlowRed] = useState(false);
    const [countdown, setCountdown] = useState('00:00:00');
    const formatDate = (timestamp) => {
        if(timestamp == -1) return ""
        const date = new Date(timestamp*1000);
        const formattedTime = format(date, 'HH:mm:ss');
      
        if (isToday(date)) {
          return `vandaag om ${formattedTime}`;
        }
      
        if (isTomorrow(date)) {
          return `morgen om ${formattedTime}`;
        }
      
        const formattedDate = format(date, 'dd-MM-yyyy');
        return `${formattedDate} om ${formattedTime}`;
      };

    useEffect(() => {
      const updateCountdown = () => {
        const timeToCheck = data.CommandType === "revolt_running" ? data.VerzondenTijd : data.Aankomst;
        const arrivalTime = new Date(timeToCheck * 1000).getTime(); // Arrival time in milliseconds
        const now = new Date().getTime()  // Current time in milliseconds
        const difference = arrivalTime - now; // Difference in milliseconds
        
      if (difference > 0) {
        const hours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);
  
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        setCountdown(formattedTime);
      } else {
        if(data.CommandType == "revolt_arising") data.CommandType = "revolt_running"
        else setCountdown('Arrived');
      }
    };

      const intervalId = setInterval(updateCountdown, 1000); // update every second

      return () => clearInterval(intervalId); // cleanup the interval on component unmount
    }, [data]);

    useEffect(() => {
      //Set glow state
      if(!data.EigenCommand && data.CommandType != "support") {
        console.log("Glow", data.EigenCommand, data.CommandType, data.Bestemming)
        setGlowRed(true)
      }
      else if(data.EigenCommand && data.Uploader == data.playerDestination && !data.return && data.CommandType != "support") setGlowRed(true)
      else setGlowRed(false)
    }, [data])

    const [isVisibleElement, setIsVisibleElement] = useState(true);
    useEffect(() => {
      const originCondition = 
        inputValueOrigin === '' || data.Afkomst == null || 
        (data.Afkomst != null && data.Afkomst.includes(inputValueOrigin));
      
      const destinationCondition = 
        inputValueDestination === '' || data.Bestemming == null || 
        (data.Bestemming != null && data.Bestemming.includes(inputValueDestination));
      console.log(isVisibleType, isVisiblePrediction, originCondition, destinationCondition)
      setIsVisibleElement(isVisibleType && isVisiblePrediction && originCondition && destinationCondition);
    }, [isVisibleType, isVisiblePrediction, inputValueOrigin, inputValueDestination]);

  return (
    <> {countdown !== "Arrived" && ( //isVisible == true && 
<li className={`fade ${!isVisibleElement ? 'fade-out' : ''}`}>
  <div className='order_information' glow={glowRed ? 'glow-red' : 'false'} data-command_type={data.CommandType}>
    <div className='left-group'>
      <div className='top-group'>
        <span className='origin_and_destination'>{data.Afkomst} &#10132; {data.Bestemming}</span>
      </div>
      <div className='bottom-group'>
        <span className='destination_time_countdown'>{countdown}</span>
        <span className='destination_time_arrival'>
        {data.CommandType === "revolt_arising" 
        ? `Opstand wordt ontketend ${formatDate(data.Aankomst)}`
        : data.CommandType === "revolt_running"
          ? ''  // If you want nothing for "revolt_running"
          : `Aankomst ${formatDate(data.Aankomst)}`
  }
        </span>
        <span className='destination_time_canncelable'>
        {data.CommandType === "revolt_running" || data.CommandType === "revolt_arising" 
          ? `(Opstand eindigt ${formatDate(data.VerzondenTijd)})` 
          : data.EigenCommand 
            ? `(Verzonden ${formatDate(data.VerzondenTijd)})` 
            : ""
        }
        </span>
      </div>
      <div className="prediction-group">
        {data.prediction?.map((pred, index) => (
          <div className="small">
            <div className='unit-icon' data-type={pred}></div>
          </div>
        )) || <div></div>}
      </div>
    </div>
    <div className='order_units'>
      {Object.entries(data.Troepen).map(([key, value]) => (
        <div className='unit-icon' data-type={key}>{value}</div>
      ))}
    </div>
  </div>
</li>
)}</>
);
};

export default OrderViewer;