import React from "react";
import { useUserContext } from "../context/userContext";
import Sidebar from "./viewer/sidebar";
import UnitMovements from "./viewer/UnitMovements";
import './dashboard.scss';

const Dashboard = () => {
  const { user, logoutUser } = useUserContext();

  return (
<>
  <div className="layout-container">
      <Sidebar />
      <div className="main-content">
        <UnitMovements />
      </div>
  </div>
</>
  );
};

/**
<div>
    <h1>Dashboard </h1>
    <h2>Name : {user.displayName}</h2>
    <h2>Email : {user.email}</h2>
    <button onClick={logoutUser}>Log out</button>
</div>
 */

export default Dashboard;