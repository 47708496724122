import React, { useState, useEffect, useMemo } from 'react';
import './UnitMovements.scss';
import { format } from 'date-fns';
import { FaFilter } from 'react-icons/fa';
import MultiSelect from "../tools/multiselect";
import { getAuth } from "firebase/auth";
import OrderViewer from '../OrderViewer';

const UnitMovements = () => {
  // Initial state for buttons, all set to false (not active)
  const originalStatesTypes = [
    true,  // attack_land
    true,  // support
    true,  // attack_sea
    true,  // revolt
    false, // attack_spy
    true,  // abort
    true,  // attack_takeover
    true,  // revolt_arising
    true,   // revolt_running
    true,   // colonization
  ]
  const [buttonStates, setButtonStates] = useState(originalStatesTypes);

  // Toggle button state
  const toggleButtonState = (index) => {
    setButtonStates((prevState) =>
      prevState.map((state, i) => (i === index ? !state : state))
    );
  };

  const originalPredictions = [
    true,  // colonize_ship
    true,  // sea_monster
    true,  // trireme
    true,  // attack_ship
    true,  // big_transporter
    true,  // small_transporter
    true,  // catapult
    true,  // slinger
    true,  // hoplite
    true,  // rider
    true,  // satyr
    true,  // griffin
    true,  // harpy
    true,  // ladon
    true,  // manticore
    true,  // pegasus
  ];
  const [buttonPredictions, setButtonPredictions] = useState(originalPredictions);

  // Toggle button state
  const togglePredictionButtonState = (index) => {
    setButtonPredictions((prevState) =>
      prevState.map((state, i) => (i === index ? !state : state))
    );
  };

  // Define the button classes
  const buttonTypeClasses = [
    "attack_land", //OR attack
    "support",
    "attack_sea",
    "revolt",
    "attack_spy",
    "abort",
    "attack_takeover",
    "revolt_arising",
    "revolt_running",
    "colonization",
  ];

  // Define the button classes for predictions
const buttonPredictionClasses = [
  "colonize_ship",
  "sea_monster",
  "trireme",
  "attack_ship",
  "big_transporter",
  "small_transporter",
  "catapult",
  "slinger",
  "hoplite",
  "rider",
  "satyr",
  "griffin",
  "harpy",
  "ladon",
  "manticore",
  "pegasus",
];
  
  const [currentTime, setCurrentTime] = useState(new Date());
  const [fetchData, setFetchData] = useState([]);
  const [countdown, setCountdown] = useState(5);

  /** Set time */
  useEffect(() => {
    const interval = setInterval(() => setCurrentTime(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);

    /** Countdown logic */
    useEffect(() => {
      if (countdown > 0) {
        const interval = setInterval(() => setCountdown(prev => prev - 1), 1000);
        return () => clearInterval(interval);
      } else {
        // Fetch data when countdown reaches 0
        fetchDataFunc();
      }
    }, [countdown]);

  /** Fetch data */
  const fetchDataFunc = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    const idToken = await user.getIdToken();

    const response = await fetch('https://api.greposhare.nl/api/unitmovements', {
      headers: {
        'Authorization': `Bearer ${idToken}`
      }
    })

    const data = await response.json();
    console.log(data);
    setFetchData(data);
    setCountdown(10); // Reset countdown after fetching data
  };

  /** Initial data fetch */
  useEffect(() => {
    fetchDataFunc(); // Fetch data on component mount
  }, []);

  const [inputValueOrigin, setInputValueOrigin] = useState("");
  const [inputValueDestination, setInputValueDestination] = useState("");
  const [inputValueLimit, setInputValueLimit] = useState("20");
  const handleInputChangeOrigin = (event) => {
    setInputValueOrigin(event.target.value);
  };
  const handleInputChangeDestination = (event) => {
    setInputValueDestination(event.target.value);
  };
  const handleInputChangeLimit = (event) => {
    setInputValueLimit(event.target.value);
  };

  const handleClearFilterButton = () => {
    setInputValueOrigin("")
    setInputValueDestination("")
    setInputValueLimit("20") //TODO: it changes to 20 but the bar doesnt move to that number...
    setButtonStates(originalStatesTypes)
    setButtonPredictions(originalPredictions)
  };


  return (
    <div className="command-viewer">
      <div className="command-filters">
        <h1 className="filter-label">FILTERS</h1>
        
        <h4 className="command-types">FILTER BY TYPE</h4>
        <div className="icon-selection">
          {buttonStates.map((isActive, index) => (
            <button key={index} className={`attack_type32x32 ${buttonTypeClasses[index]} ${isActive ? "active" : "inactive"}`} onClick={() => toggleButtonState(index)} ></button>
          ))}
        </div>

        <h4 className="command-types">FILTER BY PREDICTION</h4>
        <div className="icon-selection-2">
          {buttonPredictions.map((isActive, index) => (
            <button key={index} className={`unit-icon ${isActive ? "active" : "inactive"}`} data-type={buttonPredictionClasses[index]} onClick={() => togglePredictionButtonState(index)} ></button>
          ))}
        </div>

        <h4 >FILTER BY ORIGIN</h4>
        <input type="text" placeholder="Enter value..." value={inputValueOrigin} onChange={handleInputChangeOrigin}/>
        <h4 >FILTER BY DESTINATION</h4>
        <input type="text" placeholder="Enter value..." value={inputValueDestination} onChange={handleInputChangeDestination}/>

        <h4 className="order-limit">ORDER LIMIT: {inputValueLimit}</h4>
        <input type="range" min="0" max="20" onChange={handleInputChangeLimit}/>

        <button className="WebButton" onClick={handleClearFilterButton}>CLEAR FILTERS</button>
      </div>

      <div className="command-overview">
        <div className="command-overview-title">
          <div className="server-time">
            Time: {format(currentTime, 'HH:mm:ss')}
          </div>
          <div className="title">
            Command Overview ({fetchData.length} commands)
          </div>
          <div className="refresh-timer">
            Refreshing in {countdown} seconds
          </div>
        </div>
        <div className="command-overview-body">
          <ul className='orders'>
          {fetchData
          .sort((a, b) => new Date(a.Aankomst) - new Date(b.Aankomst)) // Sort by Aankomst date
          .map((order, index) => {
            let commandType = order.CommandType === "attack" ? "attack_land" : order.CommandType;

            const commandTypeIndex = buttonTypeClasses.indexOf(commandType);
            const isVisibleType = commandTypeIndex !== -1 && buttonStates[commandTypeIndex];
            
            const isVisiblePrediction = !order.prediction || !order.prediction.length || order.prediction.some(prediction => {
              const predictionIndex = buttonPredictionClasses.indexOf(prediction);
              return predictionIndex !== -1 && buttonPredictions[predictionIndex];
            });
            

            return (
              <OrderViewer
                data={order}
                currentTime={currentTime}
                isVisibleType={isVisibleType} // Pass visibility state as a prop
                isVisiblePrediction={isVisiblePrediction} // Pass visibility state as a prop
                inputValueOrigin={inputValueOrigin}
                inputValueDestination={inputValueDestination}
              />
            );
          })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UnitMovements;